body {
  background-color: whitesmoke !important;
}

.container {
  /* display: flex;
  flex-wrap: wrap; */
  width: 500px;
  height: 500px;

  /* flex-basis: 33%; */
  /* display: flex; */
}

.child {
  display: flex;
  flex-wrap: wrap;
  /* flex-basis: 33%; */
  align-items: center;
  border: none;
  background-color: whitesmoke;
  /* width: 33%; */
  justify-content: space-around;
}
