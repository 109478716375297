.photo {
  height: 200px;
  width: 200px;
  margin: 8px 25px;
  border-radius: 10%;
  /* border: 5px solid rgb(241, 241, 241); */
  /* border-radius: 3px; */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  cursor: pointer;
}
